export const CountryDialingCodeList = [
  { text: 'Afghanistan (+93)', value: '+93' },
  { text: 'Albania (+355)', value: '+355' },
  { text: 'Algeria (+213)', value: '+213' },
  { text: 'Andorra (+376)', value: '+376' },
  { text: 'Angola (+244)', value: '+244' },
  { text: 'Antigua and Barbuda (+1268)', value: '+1268' },
  { text: 'Argentina (+54)', value: '+54' },
  { text: 'Armenia (+374)', value: '+374' },
  { text: 'Australia (+61)', value: '+61' },
  { text: 'Austria (+43)', value: '+43' },
  { text: 'Azerbaijan (+994)', value: '+994' },
  { text: 'Bahamas (+1242)', value: '+1242' },
  { text: 'Bahrain (+973)', value: '+973' },
  { text: 'Bangladesh (+880)', value: '+880' },
  { text: 'Barbados (+1246)', value: '+1246' },
  { text: 'Belarus (+375)', value: '+375' },
  { text: 'Belgium (+32)', value: '+32' },
  { text: 'Belize (+501)', value: '+501' },
  { text: 'Benin (+229)', value: '+229' },
  { text: 'Bhutan (+975)', value: '+975' },
  { text: 'Bolivia (+591)', value: '+591' },
  { text: 'Bosnia and Herzegovina (+387)', value: '+387' },
  { text: 'Botswana (+267)', value: '+267' },
  { text: 'Brazil (+55)', value: '+55' },
  { text: 'Brunei (+673)', value: '+673' },
  { text: 'Bulgaria (+359)', value: '+359' },
  { text: 'Burkina Faso (+226)', value: '+226' },
  { text: 'Burundi (+257)', value: '+257' },
  { text: 'Cabo Verde (+238)', value: '+238' },
  { text: 'Cambodia (+855)', value: '+855' },
  { text: 'Cameroon (+237)', value: '+237' },
  { text: 'Central African Republic (+236)', value: '+236' },
  { text: 'Chad (+235)', value: '+235' },
  { text: 'Chile (+56)', value: '+56' },
  { text: 'China (+86)', value: '+86' },
  { text: 'Colombia (+57)', value: '+57' },
  { text: 'Comoros (+269)', value: '+269' },
  { text: 'Congo (Congo-Brazzaville) (+242)', value: '+242' },
  { text: 'Congo (DRC) (+243)', value: '+243' },
  { text: 'Costa Rica (+506)', value: '+506' },
  { text: 'Croatia (+385)', value: '+385' },
  { text: 'Cuba (+53)', value: '+53' },
  { text: 'Cyprus (+357)', value: '+357' },
  { text: 'Czechia (Czech Republic) (+420)', value: '+420' },
  { text: 'Denmark (+45)', value: '+45' },
  { text: 'Djibouti (+253)', value: '+253' },
  { text: 'Dominica (+1767)', value: '+1767' },
  { text: 'Dominican Republic (+1809)', value: '+1809' },
  { text: 'Ecuador (+593)', value: '+593' },
  { text: 'Egypt (+20)', value: '+20' },
  { text: 'El Salvador (+503)', value: '+503' },
  { text: 'Equatorial Guinea (+240)', value: '+240' },
  { text: 'Eritrea (+291)', value: '+291' },
  { text: 'Estonia (+372)', value: '+372' },
  { text: 'Eswatini (+268)', value: '+268' },
  { text: 'Ethiopia (+251)', value: '+251' },
  { text: 'Fiji (+679)', value: '+679' },
  { text: 'Finland (+358)', value: '+358' },
  { text: 'France (+33)', value: '+33' },
  { text: 'Gabon (+241)', value: '+241' },
  { text: 'Gambia (+220)', value: '+220' },
  { text: 'Georgia (+995)', value: '+995' },
  { text: 'Germany (+49)', value: '+49' },
  { text: 'Ghana (+233)', value: '+233' },
  { text: 'Greece (+30)', value: '+30' },
  { text: 'Grenada (+1473)', value: '+1473' },
  { text: 'Guatemala (+502)', value: '+502' },
  { text: 'Guinea (+224)', value: '+224' },
  { text: 'Guinea-Bissau (+245)', value: '+245' },
  { text: 'Guyana (+592)', value: '+592' },
  { text: 'Haiti (+509)', value: '+509' },
  { text: 'Honduras (+504)', value: '+504' },
  { text: 'Hungary (+36)', value: '+36' },
  { text: 'Iceland (+354)', value: '+354' },
  { text: 'India (+91)', value: '+91' },
  { text: 'Indonesia (+62)', value: '+62' },
  { text: 'Iran (+98)', value: '+98' },
  { text: 'Iraq (+964)', value: '+964' },
  { text: 'Ireland (+353)', value: '+353' },
  { text: 'Israel (+972)', value: '+972' },
  { text: 'Italy (+39)', value: '+39' },
  { text: 'Jamaica (+1876)', value: '+1876' },
  { text: 'Japan (+81)', value: '+81' },
  { text: 'Jordan (+962)', value: '+962' },
  { text: 'Kenya (+254)', value: '+254' },
  { text: 'Kiribati (+686)', value: '+686' },
  { text: 'Kuwait (+965)', value: '+965' },
  { text: 'Kyrgyzstan (+996)', value: '+996' },
  { text: 'Laos (+856)', value: '+856' },
  { text: 'Latvia (+371)', value: '+371' },
  { text: 'Lebanon (+961)', value: '+961' },
  { text: 'Lesotho (+266)', value: '+266' },
  { text: 'Liberia (+231)', value: '+231' },
  { text: 'Libya (+218)', value: '+218' },
  { text: 'Liechtenstein (+423)', value: '+423' },
  { text: 'Lithuania (+370)', value: '+370' },
  { text: 'Luxembourg (+352)', value: '+352' },
  { text: 'Madagascar (+261)', value: '+261' },
  { text: 'Malawi (+265)', value: '+265' },
  { text: 'Malaysia (+60)', value: '+60' },
  { text: 'Maldives (+960)', value: '+960' },
  { text: 'Mali (+223)', value: '+223' },
  { text: 'Malta (+356)', value: '+356' },
  { text: 'Marshall Islands (+692)', value: '+692' },
  { text: 'Mauritania (+222)', value: '+222' },
  { text: 'Mauritius (+230)', value: '+230' },
  { text: 'Mexico (+52)', value: '+52' },
  { text: 'Micronesia (+691)', value: '+691' },
  { text: 'Moldova (+373)', value: '+373' },
  { text: 'Monaco (+377)', value: '+377' },
  { text: 'Mongolia (+976)', value: '+976' },
  { text: 'Montenegro (+382)', value: '+382' },
  { text: 'Morocco (+212)', value: '+212' },
  { text: 'Mozambique (+258)', value: '+258' },
  { text: 'Myanmar (Burma) (+95)', value: '+95' },
  { text: 'Namibia (+264)', value: '+264' },
  { text: 'Nauru (+674)', value: '+674' },
  { text: 'Nepal (+977)', value: '+977' },
  { text: 'Netherlands (+31)', value: '+31' },
  { text: 'New Zealand (+64)', value: '+64' },
  { text: 'Nicaragua (+505)', value: '+505' },
  { text: 'Niger (+227)', value: '+227' },
  { text: 'Nigeria (+234)', value: '+234' },
  { text: 'North Korea (+850)', value: '+850' },
  { text: 'North Macedonia (+389)', value: '+389' },
  { text: 'Norway (+47)', value: '+47' },
  { text: 'Oman (+968)', value: '+968' },
  { text: 'Pakistan (+92)', value: '+92' },
  { text: 'Palau (+680)', value: '+680' },
  { text: 'Panama (+507)', value: '+507' },
  { text: 'Papua New Guinea (+675)', value: '+675' },
  { text: 'Paraguay (+595)', value: '+595' },
  { text: 'Peru (+51)', value: '+51' },
  { text: 'Philippines (+63)', value: '+63' },
  { text: 'Poland (+48)', value: '+48' },
  { text: 'Portugal (+351)', value: '+351' },
  { text: 'Qatar (+974)', value: '+974' },
  { text: 'Romania (+40)', value: '+40' },
  { text: 'Russia (+7)', value: '+7' },
  { text: 'Rwanda (+250)', value: '+250' },
  { text: 'Saint Kitts and Nevis (+1869)', value: '+1869' },
  { text: 'Saint Lucia (+1758)', value: '+1758' },
  { text: 'Saint Vincent and the Grenadines (+1784)', value: '+1784' },
  { text: 'Samoa (+685)', value: '+685' },
  { text: 'San Marino (+378)', value: '+378' },
  { text: 'Sao Tome and Principe (+239)', value: '+239' },
  { text: 'Saudi Arabia (+966)', value: '+966' },
  { text: 'Senegal (+221)', value: '+221' },
  { text: 'Serbia (+381)', value: '+381' },
  { text: 'Seychelles (+248)', value: '+248' },
  { text: 'Sierra Leone (+232)', value: '+232' },
  { text: 'Singapore (+65)', value: '+65' },
  { text: 'Slovakia (+421)', value: '+421' },
  { text: 'Slovenia (+386)', value: '+386' },
  { text: 'Solomon Islands (+677)', value: '+677' },
  { text: 'Somalia (+252)', value: '+252' },
  { text: 'South Africa (+27)', value: '+27' },
  { text: 'South Korea (+82)', value: '+82' },
  { text: 'South Sudan (+211)', value: '+211' },
  { text: 'Spain (+34)', value: '+34' },
  { text: 'Sri Lanka (+94)', value: '+94' },
  { text: 'Sudan (+249)', value: '+249' },
  { text: 'Suriname (+597)', value: '+597' },
  { text: 'Sweden (+46)', value: '+46' },
  { text: 'Switzerland (+41)', value: '+41' },
  { text: 'Syria (+963)', value: '+963' },
  { text: 'Taiwan (+886)', value: '+886' },
  { text: 'Tajikistan (+992)', value: '+992' },
  { text: 'Tanzania (+255)', value: '+255' },
  { text: 'Thailand (+66)', value: '+66' },
  { text: 'Timor-Leste (+670)', value: '+670' },
  { text: 'Togo (+228)', value: '+228' },
  { text: 'Tonga (+676)', value: '+676' },
  { text: 'Trinidad and Tobago (+1868)', value: '+1868' },
  { text: 'Tunisia (+216)', value: '+216' },
  { text: 'Turkey (+90)', value: '+90' },
  { text: 'Turkmenistan (+993)', value: '+993' },
  { text: 'Tuvalu (+688)', value: '+688' },
  { text: 'Uganda (+256)', value: '+256' },
  { text: 'Ukraine (+380)', value: '+380' },
  { text: 'United Arab Emirates (+971)', value: '+971' },
  { text: 'United Kingdom (+44)', value: '+44' },
  { text: 'United States (+1)', value: '+1' },
  { text: 'Uruguay (+598)', value: '+598' },
  { text: 'Uzbekistan (+998)', value: '+998' },
  { text: 'Vanuatu (+678)', value: '+678' },
  { text: 'Venezuela (+58)', value: '+58' },
  { text: 'Vietnam (+84)', value: '+84' },
  { text: 'Yemen (+967)', value: '+967' },
  { text: 'Zambia (+260)', value: '+260' },
  { text: 'Zimbabwe (+263)', value: '+263' },
]

export const CountryDialingCodeObjectList = {
  '+93': { text: 'Afghanistan (+93)' },
  '+355': { text: 'Albania (+355)' },
  '+213': { text: 'Algeria (+213)' },
  '+376': { text: 'Andorra (+376)' },
  '+244': { text: 'Angola (+244)' },
  '+1268': { text: 'Antigua and Barbuda (+1268)' },
  '+54': { text: 'Argentina (+54)' },
  '+374': { text: 'Armenia (+374)' },
  '+61': { text: 'Australia (+61)' },
  '+43': { text: 'Austria (+43)' },
  '+994': { text: 'Azerbaijan (+994)' },
  '+1242': { text: 'Bahamas (+1242)' },
  '+973': { text: 'Bahrain (+973)' },
  '+880': { text: 'Bangladesh (+880)' },
  '+1246': { text: 'Barbados (+1246)' },
  '+375': { text: 'Belarus (+375)' },
  '+32': { text: 'Belgium (+32)' },
  '+501': { text: 'Belize (+501)' },
  '+229': { text: 'Benin (+229)' },
  '+975': { text: 'Bhutan (+975)' },
  '+591': { text: 'Bolivia (+591)' },
  '+387': { text: 'Bosnia and Herzegovina (+387)' },
  '+267': { text: 'Botswana (+267)' },
  '+55': { text: 'Brazil (+55)' },
  '+673': { text: 'Brunei (+673)' },
  '+359': { text: 'Bulgaria (+359)' },
  '+226': { text: 'Burkina Faso (+226)' },
  '+257': { text: 'Burundi (+257)' },
  '+238': { text: 'Cabo Verde (+238)' },
  '+855': { text: 'Cambodia (+855)' },
  '+237': { text: 'Cameroon (+237)' },
  '+236': { text: 'Central African Republic (+236)' },
  '+235': { text: 'Chad (+235)' },
  '+56': { text: 'Chile (+56)' },
  '+86': { text: 'China (+86)' },
  '+57': { text: 'Colombia (+57)' },
  '+269': { text: 'Comoros (+269)' },
  '+242': { text: 'Congo (Congo-Brazzaville) (+242)' },
  '+243': { text: 'Congo (DRC) (+243)' },
  '+506': { text: 'Costa Rica (+506)' },
  '+385': { text: 'Croatia (+385)' },
  '+53': { text: 'Cuba (+53)' },
  '+357': { text: 'Cyprus (+357)' },
  '+420': { text: 'Czechia (Czech Republic) (+420)' },
  '+45': { text: 'Denmark (+45)' },
  '+253': { text: 'Djibouti (+253)' },
  '+1767': { text: 'Dominica (+1767)' },
  '+1809': { text: 'Dominican Republic (+1809)' },
  '+593': { text: 'Ecuador (+593)' },
  '+20': { text: 'Egypt (+20)' },
  '+503': { text: 'El Salvador (+503)' },
  '+240': { text: 'Equatorial Guinea (+240)' },
  '+291': { text: 'Eritrea (+291)' },
  '+372': { text: 'Estonia (+372)' },
  '+268': { text: 'Eswatini (+268)' },
  '+251': { text: 'Ethiopia (+251)' },
  '+679': { text: 'Fiji (+679)' },
  '+358': { text: 'Finland (+358)' },
  '+33': { text: 'France (+33)' },
  '+241': { text: 'Gabon (+241)' },
  '+220': { text: 'Gambia (+220)' },
  '+995': { text: 'Georgia (+995)' },
  '+49': { text: 'Germany (+49)' },
  '+233': { text: 'Ghana (+233)' },
  '+30': { text: 'Greece (+30)' },
  '+1473': { text: 'Grenada (+1473)' },
  '+502': { text: 'Guatemala (+502)' },
  '+224': { text: 'Guinea (+224)' },
  '+245': { text: 'Guinea-Bissau (+245)' },
  '+592': { text: 'Guyana (+592)' },
  '+509': { text: 'Haiti (+509)' },
  '+504': { text: 'Honduras (+504)' },
  '+36': { text: 'Hungary (+36)' },
  '+354': { text: 'Iceland (+354)' },
  '+91': { text: 'India (+91)' },
  '+62': { text: 'Indonesia (+62)' },
  '+98': { text: 'Iran (+98)' },
  '+964': { text: 'Iraq (+964)' },
  '+353': { text: 'Ireland (+353)' },
  '+972': { text: 'Israel (+972)' },
  '+39': { text: 'Italy (+39)' },
  '+1876': { text: 'Jamaica (+1876)' },
  '+81': { text: 'Japan (+81)' },
  '+962': { text: 'Jordan (+962)' },
  '+254': { text: 'Kenya (+254)' },
  '+686': { text: 'Kiribati (+686)' },
  '+965': { text: 'Kuwait (+965)' },
  '+996': { text: 'Kyrgyzstan (+996)' },
  '+856': { text: 'Laos (+856)' },
  '+371': { text: 'Latvia (+371)' },
  '+961': { text: 'Lebanon (+961)' },
  '+266': { text: 'Lesotho (+266)' },
  '+231': { text: 'Liberia (+231)' },
  '+218': { text: 'Libya (+218)' },
  '+423': { text: 'Liechtenstein (+423)' },
  '+370': { text: 'Lithuania (+370)' },
  '+352': { text: 'Luxembourg (+352)' },
  '+261': { text: 'Madagascar (+261)' },
  '+265': { text: 'Malawi (+265)' },
  '+60': { text: 'Malaysia (+60)' },
  '+960': { text: 'Maldives (+960)' },
  '+223': { text: 'Mali (+223)' },
  '+356': { text: 'Malta (+356)' },
  '+692': { text: 'Marshall Islands (+692)' },
  '+222': { text: 'Mauritania (+222)' },
  '+230': { text: 'Mauritius (+230)' },
  '+52': { text: 'Mexico (+52)' },
  '+691': { text: 'Micronesia (+691)' },
  '+373': { text: 'Moldova (+373)' },
  '+377': { text: 'Monaco (+377)' },
  '+976': { text: 'Mongolia (+976)' },
  '+382': { text: 'Montenegro (+382)' },
  '+212': { text: 'Morocco (+212)' },
  '+258': { text: 'Mozambique (+258)' },
  '+95': { text: 'Myanmar (Burma) (+95)' },
  '+264': { text: 'Namibia (+264)' },
  '+674': { text: 'Nauru (+674)' },
  '+977': { text: 'Nepal (+977)' },
  '+31': { text: 'Netherlands (+31)' },
  '+64': { text: 'New Zealand (+64)' },
  '+505': { text: 'Nicaragua (+505)' },
  '+227': { text: 'Niger (+227)' },
  '+234': { text: 'Nigeria (+234)' },
  '+850': { text: 'North Korea (+850)' },
  '+389': { text: 'North Macedonia (+389)' },
  '+47': { text: 'Norway (+47)' },
  '+968': { text: 'Oman (+968)' },
  '+92': { text: 'Pakistan (+92)' },
  '+680': { text: 'Palau (+680)' },
  '+507': { text: 'Panama (+507)' },
  '+675': { text: 'Papua New Guinea (+675)' },
  '+595': { text: 'Paraguay (+595)' },
  '+51': { text: 'Peru (+51)' },
  '+63': { text: 'Philippines (+63)' },
  '+48': { text: 'Poland (+48)' },
  '+351': { text: 'Portugal (+351)' },
  '+974': { text: 'Qatar (+974)' },
  '+40': { text: 'Romania (+40)' },
  '+7': { text: 'Russia (+7)' },
  '+250': { text: 'Rwanda (+250)' },
  '+1869': { text: 'Saint Kitts and Nevis (+1869)' },
  '+1758': { text: 'Saint Lucia (+1758)' },
  '+1784': { text: 'Saint Vincent and the Grenadines (+1784)' },
  '+685': { text: 'Samoa (+685)' },
  '+378': { text: 'San Marino (+378)' },
  '+239': { text: 'Sao Tome and Principe (+239)' },
  '+966': { text: 'Saudi Arabia (+966)' },
  '+221': { text: 'Senegal (+221)' },
  '+381': { text: 'Serbia (+381)' },
  '+248': { text: 'Seychelles (+248)' },
  '+232': { text: 'Sierra Leone (+232)' },
  '+65': { text: 'Singapore (+65)' },
  '+421': { text: 'Slovakia (+421)' },
  '+386': { text: 'Slovenia (+386)' },
  '+677': { text: 'Solomon Islands (+677)' },
  '+252': { text: 'Somalia (+252)' },
  '+27': { text: 'South Africa (+27)' },
  '+82': { text: 'South Korea (+82)' },
  '+211': { text: 'South Sudan (+211)' },
  '+34': { text: 'Spain (+34)' },
  '+94': { text: 'Sri Lanka (+94)' },
  '+249': { text: 'Sudan (+249)' },
  '+597': { text: 'Suriname (+597)' },
  '+46': { text: 'Sweden (+46)' },
  '+41': { text: 'Switzerland (+41)' },
  '+963': { text: 'Syria (+963)' },
  '+886': { text: 'Taiwan (+886)' },
  '+992': { text: 'Tajikistan (+992)' },
  '+255': { text: 'Tanzania (+255)' },
  '+66': { text: 'Thailand (+66)' },
  '+670': { text: 'Timor-Leste (+670)' },
  '+228': { text: 'Togo (+228)' },
  '+676': { text: 'Tonga (+676)' },
  '+1868': { text: 'Trinidad and Tobago (+1868)' },
  '+216': { text: 'Tunisia (+216)' },
  '+90': { text: 'Turkey (+90)' },
  '+993': { text: 'Turkmenistan (+993)' },
  '+688': { text: 'Tuvalu (+688)' },
  '+256': { text: 'Uganda (+256)' },
  '+380': { text: 'Ukraine (+380)' },
  '+971': { text: 'United Arab Emirates (+971)' },
  '+44': { text: 'United Kingdom (+44)' },
  '+1': { text: 'United States (+1)' },
  '+598': { text: 'Uruguay (+598)' },
  '+998': { text: 'Uzbekistan (+998)' },
  '+678': { text: 'Vanuatu (+678)' },
  '+58': { text: 'Venezuela (+58)' },
  '+84': { text: 'Vietnam (+84)' },
  '+967': { text: 'Yemen (+967)' },
  '+260': { text: 'Zambia (+260)' },
  '+263': { text: 'Zimbabwe (+263)' },
}
